// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <div>
    <a-button
      @click="addNewItem()">
      {{$t('label.add')}}
    </a-button>

    <a-input-search
      style="width: 25vw;float: right;margin-bottom: 10px; z-index: 8;"
      :placeholder="$t('label.search')"
      v-model:value="filter"
      @search="handleSearch" />

    <a-list size="large" class="list" :loading="loading || tabLoading">
      <a-list-item :key="index" v-for="(item, index) in items" class="item">
        <a-list-item-meta>
          <template #title style="word-break: break-all">
            <a-input
              v-if="editableValueKey === index"
              class="editable-value key"
              :defaultValue="item.key"
              v-model:value="editableKey"
              @keydown.esc="editableValueKey = null"
              @pressEnter="updateData(item)">
            </a-input>
            <span v-else class="value">
              {{ item.key }}
            </span>
          </template>
          <template #description style="word-break: break-all">{{ item.description }}</template>
        </a-list-item-meta>

        <div class="item__content">
          <a-input
            v-focus="editableValueKey === index"
            v-if="editableValueKey === index"
            class="editable-value value"
            :defaultValue="item.value"
            v-model:value="editableValue"
            @keydown.esc="editableValueKey = null"
            @pressEnter="updateData(item)">
          </a-input>
          <span v-else class="value">
            {{ item.value }}
          </span>
        </div>

        <template #actions class="action">
          <tooltip-button
            :tooltip="$t('label.edit')"
            :disabled="!('addResourceDetail' in $store.getters.apis)"
            v-if="editableValueKey !== index"
            icon="edit-outlined"
            @onClick="setEditableSetting(item, index)" />
          <tooltip-button
            :tooltip="$t('label.cancel')"
            @onClick="editableValueKey = null"
            v-if="editableValueKey === index"
            iconType="CloseCircleTwoTone"
            iconTwoToneColor="#f5222d" />
          <tooltip-button
            :tooltip="$t('label.ok')"
            @onClick="updateData(item)"
            v-if="editableValueKey === index"
            iconType="CheckCircleTwoTone"
            iconTwoToneColor="#52c41a" />
        </template>
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
import { api } from '@/api'
import TooltipButton from '@/components/widgets/TooltipButton'

export default {
  components: {
    TooltipButton
  },
  name: 'SettingsTab',
  props: {
    resource: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      items: [],
      editableValueKey: null,
      editableValue: '',
      editableKey: '',
      tabLoading: false,
      filter: ''
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    resource: {
      deep: true,
      handler (newItem) {
        if (!newItem.id) return
        this.fetchData()
      }
    }
  },
  methods: {
    fetchData (callback) {
      this.editableValueKey = null
      this.tabLoading = true
      var resourceType = ''
      switch (this.$route.meta.name) {
        case 's2svpnconn':
          resourceType = 'VpnConnection'
          break
      }
      const params = {
        resourceId: this.resource.id,
        resourceType,
        list: 'all'
      }
      api('listResourceDetails', params).then(response => {
        var items = []
        for (var i in response.listresourcedetailsresponse.resourcedetail) {
          var item = response.listresourcedetailsresponse.resourcedetail[i]
          if (!this.filter || item.key.indexOf(this.filter) >= 0 || item.value.indexOf(this.filter) >= 0) {
            items.push(item)
          }
        }
        this.items = items
      }).catch(error => {
        console.error(error)
        this.$message.error(this.$t('message.error.loading.setting'))
      }).finally(() => {
        this.tabLoading = false
        if (!callback) return
        callback()
      })
    },
    updateData (item) {
      this.tabLoading = true
      var resourceType = ''
      switch (this.$route.meta.name) {
        case 's2svpnconn':
          resourceType = 'VpnConnection'
          break
      }
      api('removeResourceDetail', {
        resourceId: this.resource.id,
        resourceType,
        key: item.key
      }).then(() => {
        api('addResourceDetail', {
          resourceId: this.resource.id,
          resourceType,
          'details[0].key': this.editableKey,
          'details[0].value': this.editableValue
        }).then(() => {
          this.$message.success(`${this.$t('label.setting')} ${this.editableKey} ${this.$t('label.update.to')} ${this.editableValue}`)
        }).catch(error => {
          console.error(error)
          this.$message.error(this.$t('message.error.save.setting'))
          this.$notification.error({
            message: this.$t('label.error'),
            description: this.$t('message.error.try.save.setting')
          })
        }).finally(() => {
          this.tabLoading = false
          this.fetchData(() => {
            this.editableValueKey = null
          })
        })
      }).catch(error => {
        console.error(error)
        this.$message.error(this.$t('message.error.save.setting'))
        this.$notification.error({
          message: this.$t('label.error'),
          description: this.$t('message.error.try.save.setting')
        })
      }).finally(() => {
        this.tabLoading = false
        this.fetchData(() => {
          this.editableValueKey = null
        })
      })
    },
    setEditableSetting (item, index) {
      this.editableValueKey = index
      this.editableValue = item.value
      this.editableKey = item.key
    },
    addNewItem () {
      var item = { key: 'new', value: 'value' }
      this.items.splice(0, 0, item)
      this.setEditableSetting(item, 0)
    },
    handleSearch (value) {
      this.filter = value
      this.fetchData()
    }
  }
}
</script>

<style scoped lang="scss">
  .list {
    clear:both;
  }
  .editable-value {

    @media (min-width: 760px) {
      text-align: right;
      margin-left: 40px;
      margin-right: -40px;
    }

  }
  .item {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media (min-width: 760px) {
      flex-direction: row;
    }

    &__content {
      width: 100%;
      display: block;
      word-break: break-all;

      @media (min-width: 760px) {
        width: auto;
      }

    }

  }
  .action {
    margin-top: 20px;
    margin-left: -12px;

    @media (min-width: 480px) {
      margin-left: -24px;
    }

    @media (min-width: 760px) {
      margin-top: 0;
      margin-left: 0;
    }

  }

  .value {
    margin-top: 20px;

    @media (min-width: 760px) {
      margin-top: 0;
    }

  }

</style>
